import React, { Component } from 'react';

export class AdminVoteControll extends Component {
    constructor(props) {
        super(props);

        this.OpenShow = this.OpenShow.bind(this);
        this.CloseShow = this.CloseShow.bind(this);
        this.OpenPoll = this.OpenPoll.bind(this);
        this.ClosePoll = this.ClosePoll.bind(this);
        this.ResetAll = this.ResetAll.bind(this);
    }

    APICall(method, api_path, JSON_body) {
        this.props.rerenderParentCallback();

        fetch(api_path, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON_body
        })
    }

    OpenShow() { this.APICall('POST', 'api/StartTheShow', JSON.stringify({ "showStart": true })) }
    CloseShow() { this.APICall('POST', 'api/EndTheShow', null) }
    OpenPoll() { this.APICall('POST', 'api/OpenNewPoll', JSON.stringify({ "voteOpen": true, "show_id": this.props.activeShowId })) }
    ClosePoll() { this.APICall('POST', 'api/ClosePoll', null) }
    ResetAll() { this.APICall('DELETE', 'api/ClearAll', null) }

    render() {
        let OpenShowButton = <td><button className="btn btn-primary btn-closePoll" onClick={this.OpenShow}>Open Show</button></td>
        let CloseShowButton = <td><button className="btn btn-primary btn-closePoll" onClick={this.CloseShow}>Close Show</button></td>
        let OpenChoiceButton = <td><button className="btn btn-primary btn-closePoll" onClick={this.OpenPoll}>Open Choice</button></td>
        let CloseChoiceButton = <td><button className="btn btn-primary btn-closePoll" onClick={this.ClosePoll}>Close Choice</button></td>
        let ResetAll = <button className="btn btn-primary btn-closePoll" onClick={this.ResetAll}>Total Reset</button>

        let buttons;
        if (this.props.activeShow === false) { buttons = <tr>{OpenShowButton}</tr> }
        else if (this.props.activePoll === false) { buttons = <tr>{CloseShowButton}{OpenChoiceButton}</tr> }
        else { buttons = <tr>{CloseChoiceButton}</tr> }

        return (
            <div>
                <div className="button-container">
                    <table><tbody>{buttons}</tbody></table>
                </div>
                <br />
                {ResetAll}
            </div>
        )
    }
}